/* src/sections/ContactSection.css */

.contact {
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: center;
    padding: 60px 20px;
}

.contact h2 {
    color: var(--primary-color);
    font-size: 2.5em;
    margin-bottom: 20px;
}

.contact p {
    color: var(--text-color);
    margin-bottom: 40px;
    font-size: 1.1em;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 10px;
}

/* Contact form styling */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 500px;
    margin: 0 auto;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    font-size: 1em;
    text-align: left;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
    color: var(--text-color);
    background-color: var(--background-color);
    transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: var(--primary-color); /* Highlight border on focus */
    outline: none;
}

.contact-form textarea {
    resize: vertical;
    min-height: 100px;
}

/* Submit button styling */
.submit-button {
    background-color: var(--primary-color);
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--secondary-color);
}
