/* src/components/HeroSection.css */

.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: var(--text-color);
    overflow: hidden; /* Ensures video doesn’t spill out */
}

/* Video Background */
.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire section */
    z-index: 0; /* Places the video behind the overlay and content */
}

/* Overlay Effect */
.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    z-index: 1; /* Places the overlay above the video */
}

/* Hero Content Styling */
.hero-content {
    position: relative;
    z-index: 2; /* Places content above the overlay */
    max-width: 600px;
    padding: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.781);
}

.hero-content h1, .hero-content p {
    color: white;
}

.hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.hero-content button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.hero-content button:hover {
    background-color: var(--secondary-color);
}

/* Tablet adjustments */
@media (max-width: 1199px) {
    .hero {
        height: 80vh;
        padding: 15px;
    }

    .hero-content h1 {
        font-size: 2em;
    }
    .hero-content p {
        font-size: 1.1em;
    }
}

/* Mobile adjustments */
@media (max-width: 767px) {
    .hero {
        height: auto;
        padding: 90px;
    }
    .hero-content h1 {
        font-size: 1.8em;
    }
    .hero-content p {
        font-size: 1em;
    }
}
