/* src/components/Footer.css */

.footer {
    background-color: var(--background-color); /* Footer background adapts to global background color */
    color: var(--text-color); /* Text color adapts to global text color */
    text-align: center;
    padding: 20px;
    font-size: 0.9em;
}

.footer a {
    color: var(--primary-color); /* Links use primary color */
    text-decoration: none;
}

.footer a:hover {
    color: var(--secondary-color); /* Links change to secondary color on hover */
}
