/* Portfolio Section Styling */
.portfolio {
    text-align: center;
    padding: 60px 20px;
}

.portfolio h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: var(--primary-color);
}

.portfolio p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

/* Portfolio Cards Container */
.portfolio-cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr); /* 3 projects per row by default */
    padding: 20px;
}

/* Individual Portfolio Card */
.portfolio-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.portfolio-card:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Image Styling */
.portfolio-card img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

/* Optional: Zoom effect on image hover */
.portfolio-card:hover img {
    transform: scale(1.1); /* Zooms in the image slightly */
}

/* Text inside each Portfolio Card */
.portfolio-card h3 {
    font-size: 1.4em;
    margin: 15px 0 10px;
    color: #333;
}

.portfolio-card p {
    font-size: 1em;
    color: #666;
    padding: 0 15px 20px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .portfolio-cards {
        grid-template-columns: repeat(2, 1fr); /* 2 projects per row on tablets */
    }
}

@media (max-width: 767px) {
    .portfolio-cards {
        grid-template-columns: 1fr; /* Single-column layout on mobile */
    }

    .portfolio-card img {
        max-height: 200px; /* Optional: limit image height for smaller screens */
    }
}
