/* src/sections/ProcessSection.css */

/* Base Styles */
.process {
    background-color: #242424; /* Adapts to light or dark mode */
    color: var(--text-color); /* Applies global text color */
    text-align: center;
    padding: 60px 20px;
}

.process h2 {
    color: var(--primary-color); /* Primary color for heading */
    font-size: 2.5em;
    margin-bottom: 20px;
}

.process p {
    color: white; /* Ensure paragraph uses global text color */
    margin-bottom: 40px;
    font-size: 1.1em;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

/* Grid Container */
.process-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default for desktop: 3 columns */
    gap: 20px;
    justify-items: center;
    padding: 20px;
}

/* Individual Grid Items */
.step-card {
    background-color: var(--background-color); /* Adapts to mode */
    color: var(--text-color); /* Text inside the card uses global text color */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-icon {
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
}

.step-card h3 {
    font-size: 1.3em;
    color: var(--text-color); /* Card title uses global text color */
    margin-bottom: 10px;
}

.step-card p {
    color: var(--text-color); /* Card paragraph text uses global text color */
    font-size: 0.95em;
    line-height: 1.5;
}

/* Responsive Adjustments */

/* Tablet View (768px–1024px): 2x2 Grid */
@media (max-width: 1024px) and (min-width: 768px) {
    .process-steps {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
}

/* Mobile View (Below 768px): 1x1 Grid */
@media (max-width: 767px) {
    .process-steps {
        grid-template-columns: 1fr; /* Single-column layout for mobile */
    }
}
