/* src/index.css */

/* Reset some default styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-weight: bold;
}

p {
  color: #555;
}
